import { useContext, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import { callejeritosImages, emailPattern } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';
import { IsLoading } from '../ui/IsLoading';
import Logotipo from '../../logotipo.svg'

interface Props {
    redirect?: boolean;
    showLabels?: boolean;
};

export const LoginForm = ({ redirect = false, showLabels = false }: Props) => {

    const history = useHistory();
    const location = useLocation<any>();
    const previousObjetcURL = location.state?.from;

    const { languaje } = useContext(UserContext);
    const { iniciarSesion, isLoading, removeError } = useContext(AuthContext);

    const { formState: { errors }, handleSubmit, reset, register, setValue } = useForm<IFormInputs>();


    const onSubmit: SubmitHandler<IFormInputs> = async ({correo, contrasena}) => {
        removeError();
        
        await iniciarSesion({ correo, contrasena });

        if (localStorage.getItem('CA_JWT')) {
            
            reset(); 

            /* <img src={ callejeritosImages('./Menu/icono.png').default } alt="Menú" width={ 80 } height={ 80 } className="menu__icon"/> */

            Swal.fire({
                title:  'Bienvenido al portal de Callejeritos',
                /* text:   'dirigete al menú inferior derecho  (logotipo menú) para navegar.', */
                html: `Dirigete al menú inferior derecho <img src=${ callejeritosImages('./Menu/icono.png').default } alt="Menú" width="60" height="60" className="menu__icon"/> para navegar.`,
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            }).then(() => {
                redirect && history.push(previousObjetcURL || '/');
            });
        }

    };

    useEffect(() => {
        if(process.env.NODE_ENV === 'development') {
            setValue('correo', 'cloera@stx.com.mx');
            // setValue('correo', 'ocruz@stx.com.mx');
            // setValue('correo', 'ddominguez@stx.com.mx');
            setValue('contrasena', '0000');
        }
        
    }, [setValue]);

    return (
        <>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="row">
                    <div className="col-12">
                        { showLabels && <label htmlFor="correo">{ languaje === 'ES' ? 'Correo electrónico:' : 'Email:' }</label> }
                        <input type="email" id="correo" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú correo electrónico' : 'Email' } autoComplete="off"
                            { ...register('correo', { 
                                required: languaje === 'ES' ? 'El correo es requerido': 'The email is required',
                                pattern: { value : emailPattern, message: languaje === 'ES' ? 'Ingresa un correo válido': 'Enter a valid email' } }) } />
                        { errors.correo && <small className="text-danger">{ errors.correo.message }</small> }
                    </div>
                    <div className="col-12 mt-2">
                        { showLabels && <label htmlFor="contrasena">{ languaje === 'ES' ? 'Contraseña:' : 'Password:' }</label> }
                        <input id="contrasena" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú contraseña' : 'Password' } autoComplete="off" type="password"
                            { ...register('contrasena', { required: languaje === 'ES' ? 'El contraseña es requerida': 'The password is required' }) } />
                        { errors.contrasena && <small className="text-danger">{ errors.contrasena.message }</small> }
                    </div>
                </div>
                <div className="d-flex flex-column">
                    { isLoading
                        ? <IsLoading />
                        : <>
                            <Link to="/recovery" className="login_link">{ languaje === 'ES' ? 'Recuperar contraseña' : 'Recover password' }</Link>
                            <button className="btn login_btn btn-sm" type="submit">{ languaje === 'ES' ? 'Acceder' : 'Login' }</button>
                            { !showLabels &&  <Link to="/registro" className="btn login_btn btn-sm">{ languaje === 'ES' ? 'Registrarme' : 'Register' }</Link>}
                        </>
                    }
                </div>
            </form>
        </>
    )
}

interface IFormInputs {
    correo   : string;
    contrasena   : string;
}
