import React, { useContext} from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

import { ReCAPTCHAAPIKEY, emailPattern } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';

import kw2pApiPublic from '../../api/kw2pPublic';

import Logotipo from '../../logotipo.svg';


export const RegisterForm = () => {
    
    const { languaje } = useContext(UserContext);

    const { formState: { errors }, control, handleSubmit, reset, register, setValue, watch } = useForm<IFormInputs>();
    const correo = useWatch({name:'correo', control})

    const onSubmit: SubmitHandler<IFormInputs> = async ({nombre, correo, telefono, ciudad, pais}) => {
        try {

            const payload = {
                nombre,
                correo,
                telefono: telefono.replace(/[^0-9.]/g, ''),
                roles: ['portal'],
                credenciales: [],
                domicilio: {
                    ciudad,
                    pais
                }
            };

            await kw2pApiPublic.post<any>('/usuarios:actualizar', payload);
            debugger
            
            reset();
            
            Swal.fire({
                title: 'Registro completado',
                text: 'Valida tú correo electronico para continuar',
                footer: 'Revisa tú bandeja de entrada y/o correo no deseado (spam) para validar y activar tu cuenta y sigue las instrucciones',
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            });

        } catch (err: any) {
            debugger
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error...',
                text: JSON.stringify(err?.response?.data?.errors??err?.response?.data?.message, null, 2).replace(/[{}]/g, '')
            });
        }
    };

    const formatPhone = (val: string) => {
        debugger
        const phone = val.replace(/[^0-9.]/g, '');
        const telformated = phone.slice(0,3) + (phone.length > 3?("-" + phone.slice(3,6)):'') + (phone.length > 6?("-" + phone.slice(6)):'');
        setValue('telefono', telformated, { shouldValidate: true, shouldDirty: true });
    };
    const rulesValidateEqual = (val:string, msg:string) => (value:string) => value === val || msg

    const onChange = (value: any) => {
        setValue('captcha', value);
    };

    const disableCaptcha = !watch('captcha');

    return (
        <form onSubmit={ handleSubmit(onSubmit) } autoComplete="none">
            <div className="row g-3">
                <div className="col-12">
                    <input id="nombre" className="form-control" placeholder={ languaje === 'ES' ? 'Nombre completo' : 'Full name' } autoComplete="none"
                    { ...register('nombre', { required: languaje === 'ES' ? 'El nombre es requerido': 'The name is required' }) } required/>
                    { errors.nombre && <small className="text-danger">{ errors.nombre.message }</small> }
                </div>
                <div className="col-12 col-md-4 mt-4">
                    <input id="correo" className="form-control" type="email" placeholder={ languaje === 'ES' ? 'Correo electrónico' : 'Email' }
                    autoComplete="none"
                    { ...register('correo', { 
                        required: languaje === 'ES' ? 'El correo es requerido': 'The email is required',
                        pattern: { value : emailPattern, message: languaje === 'ES' ? 'Ingresa un correo válido': 'Enter a valid email' } }) } required/>
                    { errors.correo && <small className="text-danger">{ errors.correo.message }</small> }
                </div>
                <div className="col-12 col-md-4 mt-4">
                    <input id="correo_conf" className="form-control" type="email" placeholder={ languaje === 'ES' ? 'Validar Correo electrónico' : 'Validate Email' }
                    autoComplete="none"
                    { ...register('correo_conf', { 
                        required: languaje === 'ES' ? 'El correo es requerido': 'The email is required',
                        validate: rulesValidateEqual(correo,'El correo no coinciden',),
                        pattern: { value : emailPattern, message: languaje === 'ES' ? 'Ingresa un correo válido': 'Enter a valid email' } }) } required/>
                    { errors.correo_conf && <small className="text-danger">{ errors.correo_conf.message }</small> }
                </div>
                <div className="col-12 col-md-4 mt-4">
                    <input id="telefono" className="form-control" placeholder={ languaje === 'ES' ? 'Télefono' : 'Phone' } autoComplete="none"
                    { ...register('telefono', { 
                        required: languaje === 'ES' ? 'El telefono es requerido': 'The phone is required',
                        maxLength: { value : 12, message: languaje === 'ES' ? 'Ingresa un telefono válido': 'Enter a valid celphone' },
                        minLength: { value : 12, message: languaje === 'ES' ? 'Ingresa un telefono válido': 'Enter a valid celphone' }
                    }) }
                        onChange={ (e) => formatPhone(e.target.value) } maxLength={12} required/>
                    { errors.telefono && <small className="text-danger">{ errors.telefono.message }</small> }
                </div>
                <div className="col-12 col-md-6 mt-4">
                    <input id="ciudad" className="form-control" placeholder={ languaje === 'ES' ? 'Ciudad' : 'City' } autoComplete="none"
                    { ...register('ciudad', { required: languaje === 'ES' ? 'La ciudad es requerida': 'The city is required' }) } required/>
                    { errors.ciudad && <small className="text-danger">{ errors.ciudad.message }</small> }
                </div>
                <div className="col-12 col-md-6 mt-4">
                    <input id="pais" className="form-control" placeholder={ languaje === 'ES' ? 'Pais' : 'Country' } autoComplete="none"
                    { ...register('pais', { required: languaje === 'ES' ? 'El pais es requerido': 'The country is required' }) } required/>
                    { errors.pais && <small className="text-danger">{ errors.pais.message }</small> }
                </div>
            </div>
            <div className="d-flex flex-column mt-2">
                <ReCAPTCHA sitekey={ ReCAPTCHAAPIKEY } onChange={onChange} hl={ languaje === 'ES' ? 'es-419' : 'en' }/>
                <Link to="/login" className="login_link">{ languaje === 'ES' ? '¿Ya tienes una cuenta? Inicia sesión' : 'Do you already have an account? Log in' }</Link>
                <button className="btn login_btn btn-block" disabled={disableCaptcha} type="submit">{languaje === 'ES' ? 'Registrarme' : 'Register'}</button>
            </div>
        </form>
    )
}

interface IFormInputs {
    nombre: string;
    correo: string;
    correo_conf: string;
    telefono: string;
    ciudad: string;
    pais: string;
    captcha : string;
}
